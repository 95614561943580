import "./Services.scss";
import PageLayout from "../../layout/pagelayout/PageLayout";
import ServicesLanding from "./servicesLanding/ServicesLanding";
import { Fragment, useEffect, useState } from "react";
import ServiceList from "./serviceList/ServiceList";
import { Helmet } from "react-helmet";
import { baseUrl, getCategory, liveDomain } from "../../utils/apiData";
import { useLocation } from "react-router-dom";
import LoaderCard from "../../components/loaderCard/LoaderCard";
import axios from "axios";

const Services = () => {
  const [loader, setLoader] = useState(false);
  const [categoryList, setCategoryList] = useState([]);

  const [tab, setTab] = useState("");
  const location = useLocation();

  // Get Category API
  const getCategoryApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getCategory}`);
      if (res?.data?.success) {
        setCategoryList(res?.data?.data);
        setTab(res?.data?.data?.[0]?.category?._id)
      }
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getCategoryApi();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Award-Winning Interior Design in India | The City Interior
        </title>
        <meta
          name="description"
          content="The City Interior offers residential & commercial design solutions across India. Get a free quote or call us today: +919871206986"
        />
        <link rel="canonical" href={`${liveDomain}${location.pathname}`} />
      </Helmet>
      <PageLayout>
        {loader ? (
          <LoaderCard />
        ) : (
          <Fragment>
            <ServicesLanding tab={tab} setTab={setTab} data={categoryList} />
            <ServiceList tab={tab} data={categoryList} />
          </Fragment>
        )}
      </PageLayout>
    </Fragment>
  );
};
export default Services;
