import "./PageLayout.scss";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuScreen from "../menuscreen/MenuScreen";
import { CiMenuFries } from "react-icons/ci";
import { menuActions } from "../../store";

const PageLayout = ({ children }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition >= 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const {status} = useSelector(state => state.menu);

  const dispatch = useDispatch();

  return (
    <div className="page_layout">
      <button className="menuBtn" onClick={() =>  dispatch(menuActions.menuChanger(true))}>
        <CiMenuFries />
      </button>
      {status && <MenuScreen />}
      <Header isScrolled={isScrolled} />
      {children}
      <Footer />
    </div>
  );
};

export default PageLayout;
