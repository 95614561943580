import "./TitleStrip.scss";
import { motion } from "framer-motion";
const TitleStrip = ({ title }) => {
  return (
    <section className="title_strip">
      <div className="content_wrap">
      <motion.h1
          initial={{ translateX: 150, opacity: 0 }}
          whileInView={{ translateX: 0, opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 0.8,
            delay: 0.2,
          }}
        >
          {title}
        </motion.h1>
      </div>
    </section>
  );
};

export default TitleStrip;
