import { useLocation, useParams } from "react-router-dom";
import PageLayout from "../../layout/pagelayout/PageLayout";
import ImageTitleStrip from "../../components/imageTitleStrip/ImageTitleStrip";
import { images } from "../../utils/images";
import TriNavigate from "../../components/trinavigate/TriNavigate";
import ServiceSlider from "./serviceSlider/ServiceSlider";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { baseUrl, liveDomain, serviceProductDetail } from "../../utils/apiData";
import LoaderCard from "../../components/loaderCard/LoaderCard";
import ServiceLR from "./serviceLr/ServiceLR";
import { Helmet } from "react-helmet";

const ServicesDetail = () => {
  const { slug } = useParams();
  const [loader, setLoader] = useState(false);
  const [info, setInfo] = useState({});
  const [services, setService] = useState([]);

  const metaDataInfo = [
    {
      slug: "pooja-room-interiors",
      title: "Beautiful Pooja Room Designs & Interiors | The City Interior",
      des: "Create a sacred space with our stunning Pooja Room designs. Explore a variety of styles & get expert advice. Call For a free consultation: +919871206986",
    },
    {
      slug: "guest-room-interiors",
      title: "Stylish & Comfortable Guest Room Interiors | The City Interior",
      des: "Impress your guests with beautifully designed guest rooms. Our experts create inviting spaces. Contact us for a free consultation: +919871206986",
    },
    {
      slug: "kids-bedroom-interiors",
      title: "Fun & Functional Kids Bedroom Interiors | The City Interior",
      des: "Create a magical space for your little ones. Our expert designers specialize in fun, functional kids bedroom. Contact us for a free consultation: +919871206986",
    },
    {
      slug: "bedroom-interiors",
      title: "Elevate Your Bedroom with Expert Design | The City Interior",
      des: "Transform your bedroom into a serene sanctuary. Our designers specialize in creating stylish & functional interiors. Call for a free consultation: +919871206986",
    },
    {
      slug: "living-room-interiors",
      title: "Stylish Living Room Interiors | The City Interior",
      des: "Create a welcoming and stylish living room with our expert design services. From modern to traditional. Contact us for a free consultation: +919871206986",
    },
    {
      slug: "office-interiors",
      title: "Modern & Functional Office Interiors | The City Interior",
      des: "Create a productive workspace. Our designers specialize in modern, functional office interiors. Contact us for a free consultation: +919871206986",
    },
    {
      slug: "cafe-interiors",
      title: "Cozy & Inviting Cafe Interiors | The City Interior",
      des: "Create a memorable dining experience. Our designers specialize in cozy & inviting cafe interiors. Contact us for a free consultation: +919871206986",
    },
    {
      slug: "restaurant-interiors",
      title: "Stylish & Inviting Restaurant Interiors | The City Interior",
      des: "Create a memorable dining experience. Our designers specialize in stylish & functional restaurant interiors. Contact us for a free consultation: +919871206986",
    },
    {
      slug: "shop-interiors",
      title: "Inviting & Engaging Shop Interiors | The City Interior",
      des: "Create a captivating shopping experience. Our designers specialize in stylish & functional shop interiors. Contact us for a free consultation: +919871206986",
    },
    {
      slug: "bpo-interiors",
      title: "Efficient & Stylish Commercial Interiors | The City Interior",
      des: "Create a productive workspace. Our designer specialize in modern, commercial interior, including BPO office space. call for a free consultation: +919871206986",
    },
    {
      slug: "salon-interiors",
      title: "Luxurious & Inviting Salon Interiors | The City Interior",
      des: "Create a relaxing and luxurious atmosphere. Our designers specialize in stylish & functional salon interiors. Contact us for a free consultation: +919871206986",
    },
    {
      slug: "bar-interiors",
      title: "Trendsetting Bar Interiors | The City Interior",
      des: "Create a memorable ambiance for your patrons. Our designers specialize in stylish & functional bar interiors. Contact us for a free consultation: +919871206986",
    },
    {
      slug: "kicthen-interiors",
      title: "Efficient & Stylish Modular Kitchen Designs | The City Interior",
      des: "Maximize your kitchen space with our custom-designed modular kitchens. our designer specialize in creating Luxurious kitchens. Call Now: +919871206986",
    },
    {
      slug: "bathroom-interiors",
      title: "Modern & Luxurious Bathroom Designs | The City Interior",
      des: "Transform your bathroom into a spa-like oasis. Our designers specialize in creating stylish bathroom design. Contact us for a free consultation: +919871206986",
    },
  ];
  const location = useLocation();
  const target = () => {
    return metaDataInfo.find((obj) => obj.slug === slug);
  };

  const serviveDatailApi = async () => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${serviceProductDetail}`, {
        slug: `${slug}`,
      });
      if (res?.data?.success) {
        setService(res?.data?.data?.service);
        setInfo(res?.data?.data?.blogs);
      }
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    serviveDatailApi();
  }, [slug]);

  return (
    <Fragment>

      <PageLayout>
      <Helmet>
        <title>{target()?.title}</title>
        <meta name="description" content={target()?.des} />
        <link rel="canonical" href={`${liveDomain}${location?.pathname}`} />
      </Helmet>
        {loader ? (
          <LoaderCard />
        ) : (
          <Fragment>
            <ImageTitleStrip
              type="Services"
              name={slug}
              img={images.InteriorF}
            />
            <ServiceLR info={info} />

            <ServiceSlider data={services} />
            <TriNavigate
              imgA={images.InteriorA}
              imgB={images.InteriorB}
              imgC={images.InteriorC}
              titleA="about"
              titleB="Portfolio"
              titleC="Contact"
              pathA="/about"
              pathB="/portfolio"
              pathC="/contact"
            />
          </Fragment>
        )}
      </PageLayout>
    </Fragment>
  );
};

export default ServicesDetail;
