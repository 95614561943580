import "./ImageTitleStrip.scss";
import { motion } from "framer-motion";
import { IoIosArrowForward } from "react-icons/io";

const ImageTitleStrip = ({ type, name, img }) => {
  return (
    <section className="img_title_strip">
      <img src={img} alt="services" className="bg" loading="lazy" />
      <div className="content_wrap">
        <motion.h2
          initial={{ translateX: 150, opacity: 0 }}
          whileInView={{ translateX: 0, opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 0.8,
            delay: 0.2,
          }}
          className="title_header"
        >
          {type} <IoIosArrowForward /> {name}
        </motion.h2>
      </div>
    </section>
  );
};

export default ImageTitleStrip;
