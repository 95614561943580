import logoLight from "../assets/images/logo/logoLight.png";
import logoDark from "../assets/images/logo/logoDark.png";


import InteriorA from "../assets/images/interiorA.jpg";
import InteriorB from "../assets/images/interiorB.jpg";
import InteriorC from "../assets/images/interiorC.jpg";
import InteriorD from "../assets/images/interiorD.jpg";
import InteriorE from "../assets/images/interiorE.jpg";
import InteriorF from "../assets/images/interiorF.jpg";
import InteriorG from "../assets/images/interiorG.jpg";

import sublinkAA from "../assets/images/submenu/sublinkAA.jpg";
import sublinkA from "../assets/images/submenu/sublinkA.jpg";
import sublinkB from "../assets/images/submenu/sublinkB.jpg";
import sublinkC from "../assets/images/submenu/sublinkC.jpg";
import sublinkD from "../assets/images/submenu/sublinkD.jpg";
import sublinkE from "../assets/images/submenu/sublinkE.jpg";
import sublinkF from "../assets/images/submenu/sublinkF.jpg";
import sublinkG from "../assets/images/submenu/sublinkG.jpg";
import sublinkH from "../assets/images/submenu/sublinkH.jpg";
import sublinkI from "../assets/images/submenu/sublinkI.jpg";
import sublinkJ from "../assets/images/submenu/sublinkJ.jpg";

import whiteLogo from "../assets/images/logo/whitelogo.svg";

// Home
import acousticA from "../assets/images/home/acousticA.jpg";
import acousticB from "../assets/images/home/acousticB.jpg";
import blogSec from "../assets/images/home/blogsec.jpg";
import studioA from "../assets/images/home/studioA.jpg";
import homeCta from "../assets/images/home/homeCta.jpg";

import consultA from "../assets/images/home/consultA.jpg";
import consultB from "../assets/images/home/consultB.jpg";
import consultC from "../assets/images/home/consultC.jpg";

// Blogs
import blogA from "../assets/images/blogs/blogA.jpg";
import blogB from "../assets/images/blogs/blogB.png";
import blogC from "../assets/images/blogs/blogC.jpg";

// Contact
import contact from "../assets/images/contact/contact.jpg";
import map from "../assets/images/contact/map.jpg";

// Portfolio
import portfolioDetail from "../assets/images/portfolio/portfolioDetail.webp";
import portfolioA from "../assets/images/portfolio/portfolioA.jpg";
import portfolioB from "../assets/images/portfolio/portfolioB.jpg";
import portfolioC from "../assets/images/portfolio/portfolioC.jpg";
import portfolioD from "../assets/images/portfolio/portfolioD.webp";
import portfolioE from "../assets/images/portfolio/portfolioE.jpg";
import portfolioF from "../assets/images/portfolio/portfolioF.jpg";
import portfolioG from "../assets/images/portfolio/portfolioG.jpg";
import portfolioH from "../assets/images/portfolio/portfolioH.jpg";
import portfolioI from "../assets/images/portfolio/portfolioI.jpg";
import portfolioJ from "../assets/images/portfolio/portfolioJ.jpg";
import portfolioK from "../assets/images/portfolio/portfolioK.jpg";

import servicesBanner from "../assets/images/servicesBanner.jpg";

import servSlideA from "../assets/images/services/serviceA.jpg";
import servSlideB from "../assets/images/services/serviceB.jpg";
import servSlideC from "../assets/images/services/serviceC.jpg";
import servSlideD from "../assets/images/services/serviceD.jpg";
import servSlideE from "../assets/images/services/serviceE.jpg";
import servSlideF from "../assets/images/services/serviceF.jpg";
import servSlideG from "../assets/images/services/serviceG.jpg";

import bar from "../assets/images/original/bar.jpg";
import bath from "../assets/images/original/bath.jpg";
import bed from "../assets/images/original/bed.jpg";
import bpo from "../assets/images/original/bpo.jpg";
import cafe from "../assets/images/original/cafe.webp";
import guest from "../assets/images/original/guest.webp";
import kids from "../assets/images/original/kids.jpg";
import kitchen from "../assets/images/original/kitchen.jpg";
import living from "../assets/images/original/living.jpg";
import office from "../assets/images/original/office.jpg";
import pooja from "../assets/images/original/pooja.jpg";
import restaurant from "../assets/images/original/resturant.jpg";
import salon from "../assets/images/original/salon.jpg";
import shop from "../assets/images/original/shop.jpg";

export const images = {
  logoDark,
  logoLight,

  servicesBanner,
  // Header
  whiteLogo,

  InteriorA,
  InteriorB,
  InteriorC,
  InteriorD,
  InteriorE,
  InteriorF,
  InteriorG,

  sublinkAA,
  sublinkA,
  sublinkB,
  sublinkC,
  sublinkD,
  sublinkE,
  sublinkF,
  sublinkG,
  sublinkH,
  sublinkI,
  sublinkJ,

  // Home
  acousticA,
  acousticB,
  blogSec,
  studioA,
  homeCta,

  consultA,
  consultB,
  consultC,

  // Blog
  blogA,
  blogB,
  blogC,

  // Contact
  contact,
  map,


  // Portfolio
  portfolioDetail,

  portfolioA,
  portfolioB,
  portfolioC,
  portfolioD,
  portfolioE,
  portfolioF,
  portfolioG,
  portfolioH,
  portfolioI,
  portfolioJ,
  portfolioK,

  servSlideA,
  servSlideB,
  servSlideC,
  servSlideD,
  servSlideE,
  servSlideF,
  servSlideG,

  bar,
  bath,
  bed,
  bpo,
  cafe,
  guest,
  kids,
  kitchen,
  living,
  office,
  pooja,
  restaurant,
  salon,
  shop,
};
