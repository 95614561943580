import "./ServiceLR.scss";
import { motion } from "framer-motion";

const ServiceLR = ({ info }) => {
  return (
    <section className="service_lr p_t">
      <div className="content_wrap">
        <motion.h1
          initial={{ scaleY: 0, opacity: 0 }}
          whileInView={{ scaleY: 1, opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 0.6,
            delay: 0.2,
          }}
        >
          {info?.main_title}
        </motion.h1>

        <div dangerouslySetInnerHTML={{ __html: info?.description }} />
      </div>
    </section>
  );
};
export default ServiceLR;
