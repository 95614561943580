import "./ServiceSlider.scss";
import { Fragment, useState } from "react";
import { images } from "../../../utils/images";
import { motion } from "framer-motion";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { HiArrowNarrowRight } from "react-icons/hi";
import { baseUrl } from "../../../utils/apiData";

const ServiceSlider = ({data}) => {
  const serviceData = [
    {
      id: 1,
      img: images.servSlideA,
      title: "Architectural Assessment",
      text: "Whether a new build or existing, we can assess your plans or existing home to come up with options for maximum space utilisation, comfort and flow.",
    },
    {
      id: 2,
      img: images.servSlideB,
      title: "Space Planning",
      text: "Once your floorplan is agreed to, a full furniture space plan exercise takes place which then brings the rooms to life so you can start to envision what your dream home will look like.",
    },
    {
      id: 3,
      img: images.servSlideC,
      title: "Modular Kitchen",
      text: "Transform your kitchen to the heart of your home with the help of The Cityinterior. From coffee dates to dinner parties, our end-to-end design and installation services will turn your kitchen into a stylish and functional space",
    },
    {
      id: 4,
      img: images.servSlideD,
      title: "Interior Finishes",
      text: "Full proposals, selections, and coordination of all interior finishes (floors, walls ceilings, lighting etc.)",
    },
    {
      id: 5,
      img: images.servSlideE,
      title: "Furniture and Joinery",
      text: "Full design and specification of all joinery and items of a built-in nature i.e. dressing room, sideboard/servers, wall units etc. Full coordination and presentation, procurement and installation of all loose furniture.",
    },
    {
      id: 6,
      img: images.servSlideF,
      title: "Styling, Art and Accessories",
      text: "Add the final touches to make your home truly individual.",
    },
  ];

  const [num, setNum] = useState(0);
  const [prev, setPrev] = useState(data?.length);



  const switchSlide = () => {
    setNum((prev) => {
      setPrev(prev);
      if (prev === data.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };

  return (
    <section className="services_slide p_b p_t">
      <div className="content_wrap">
        <div className="title">
          <h2 className="title_header">Our Services</h2>
        </div>
        <div className="detail_grid">
        <div className="slide_part">
          <div className="slide_box">
            <div className="btn_wrap">
            <button onClick={switchSlide}><HiArrowNarrowLeft /></button>
            <button onClick={switchSlide}><HiArrowNarrowRight /></button>
            </div>
            {data?.map((item, j) => {
              if (j === num || j === prev) {
                return (
                  <motion.img
                  key={item?._id}
                    initial={{ scaleX: 0 }}
                    whileInView={{ scaleX: 1 }}
                    transition={{
                      ease: "easeInOut",
                      duration: 0.6,
                    }}
                    src={`${baseUrl}/${item?.image}`}
                    alt="services"
                    style={{ zIndex: j === num ? "1" : "0" }}
                  />
                );
              }
            })}
          </div>
        </div>
        <div className="text_part">
          {data?.map((item, j) => {
            if (j === num) {
              return (
                <Fragment key={item?._id}>
                  <motion.h2
                    initial={{ scaleY: 0, opacity: 0 }}
                    whileInView={{ scaleY: 1, opacity: 1 }}
                    transition={{
                      ease: "easeInOut",
                      duration: 0.6,
                    }}
                    className="title_header"
                  >
                    {item?.title || "-"}
                  </motion.h2>
                
                  <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                </Fragment>
              );
            }
          })}
        </div>
      </div>
      </div>
    </section>
  );
};

export default ServiceSlider;
