import PageLayout from "../../layout/pagelayout/PageLayout";
import { images } from "../../utils/images";
import { useNavigate, useParams } from "react-router-dom";
import HomeCta from "../../components/homecta/HomeCta";
import PortfolioDetailLanding from "../portffolioDetail/portfolioDetailLanding/PortfolioDetailLanding";
import { baseUrl, getBlogDetail } from "../../utils/apiData";
import LoaderCard from "../../components/loaderCard/LoaderCard";
import BlogInfo from "./blogInfo/BlogInfo";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";

const BlogDetails = () => {
  const { slug } = useParams();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});

  const navigate = useNavigate();

  const getBlogDetailApi = async () => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${getBlogDetail}`, {
        slug: `${slug}`,
      });
      if (res?.data?.success) {
        setData(res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getBlogDetailApi();
  }, []);

  return (
    <PageLayout>
      {loader ? (
        <LoaderCard />
      ) : (
        <Fragment>
          <PortfolioDetailLanding img={`${baseUrl}/${data?.image}`} />
          <BlogInfo data={data} />
          <HomeCta
            title="Contact Us"
            text="Lorem ipsum dolor sit"
            banner={images.homeCta}
            btnText="Get In Touch"
            clickHandler={() => navigate("/contact")}
          />
        </Fragment>
      )}
    </PageLayout>
  );
};

export default BlogDetails;
