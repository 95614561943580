import "./LatestHomeLanding.scss";
import { images } from "../../../utils/images";
import Slider from "react-slick";
import { motion } from "framer-motion";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { useRef } from "react";
import { baseUrl } from "../../../utils/apiData";

const LatestHomeLanding = ({ dataList, goToTarget }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
  };
  
  const sliderRef = useRef(null);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <section className="latest_home_landing">
      <div className="latest_landing_slide">
        <Slider ref={sliderRef} {...settings}>
          {dataList?.map((data) => {
            return (
              <div className="landing_slide" key={data._id}>
                <img src={`${baseUrl}/${data?.image}`} alt="interior" loading="lazy" />
                <div className="overlay"></div>
                <div className="text_content">
                  <motion.h2
                    initial={{ scaleY: 0, opacity: 0 }}
                    whileInView={{ scaleY: 1, opacity: 1 }}
                    transition={{
                      ease: "easeInOut",
                      duration: 0.6,
                    }}
                    className="title_header"
                  >
                    {data.title}
                  </motion.h2>
                  <motion.button
                    initial={{ scaleY: 0, opacity: 0 }}
                    whileInView={{ scaleY: 1, opacity: 1 }}
                    transition={{
                      ease: "easeInOut",
                      duration: 0.6,
                      delay: 0.2,
                    }}
                    onClick={goToTarget}
                  >
                    Explore
                  </motion.button>
                </div>
              </div>
            );
          })}
        </Slider>
        <button
          type="button"
          className="controle_btn prev"
          onClick={handlePrev}
        >
          <IoIosArrowBack />
        </button>
        <button
          type="button"
          className="controle_btn next"
          onClick={handleNext}
        >
          <IoIosArrowForward />
        </button>
      </div>
    </section>
  );
};

export default LatestHomeLanding;
