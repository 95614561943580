import "./LoaderCard.scss";

const LoaderCard = () => {
    return <div className="loader_card">
            <div className="outer">                
            </div>
            <div className="inner">
            </div>
    </div>
}
export default LoaderCard;