import "./ContactLanding.scss";
import { motion } from "framer-motion";

const ContactLanding = ({data}) => {
  return (
    <section className="contact_landing">
      <div className="circle"></div>
      <div className="content_wrap">
        <motion.h1
          initial={{ scaleY: 0, opacity: 0 }}
          whileInView={{ scaleY: 1, opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 0.6,
            delay: 0.2,
          }}
        >
       {data?.heading || "-"}
        </motion.h1>
        <div className="info_side">
          <div className="text">
            <motion.p
              initial={{ scaleY: 0, opacity: 0 }}
              whileInView={{ scaleY: 1, opacity: 1 }}
              transition={{
                ease: "easeInOut",
                duration: 0.6,
                delay: 0.2,
              }}
              className="t_24"
            >
              <b>Email:</b>&nbsp;&nbsp;
              <a href={`mailto:${data?.email}`}>
               {data?.email || "-"}
              </a>
            </motion.p>
            <motion.p
              initial={{ scaleY: 0, opacity: 0 }}
              whileInView={{ scaleY: 1, opacity: 1 }}
              transition={{
                ease: "easeInOut",
                duration: 0.6,
                delay: 0.4,
              }}
              className="t_24"
            >
              <b>Phone:</b>&nbsp;&nbsp;<a href={`tel:${data?.number}`}>{data?.number || "-"}</a>
            </motion.p>
            <motion.p
              initial={{ scaleY: 0, opacity: 0 }}
              whileInView={{ scaleY: 1, opacity: 1 }}
              transition={{
                ease: "easeInOut",
                duration: 0.6,
                delay: 0.6,
              }}
              className="t_24"
            >
              <b>Address:</b>&nbsp;&nbsp;{data?.address || "-"}
            </motion.p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactLanding;
