import "./Blogs.scss";
import PageLayout from "../../layout/pagelayout/PageLayout";
import TitleStrip from "../../components/titleStrip/TitleStrip";
import BlogList from "./blogList/BlogList";
import { images } from "../../utils/images";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { baseUrl, getBlogs, liveDomain } from "../../utils/apiData";
import { useLocation } from "react-router-dom";
import axios from "axios";
import LoaderCard from "../../components/loaderCard/LoaderCard";

const Blogs = () => {
  const [loader, setLoader] = useState(false);
  const [blogs, setBlogs] = useState([]);

  const location = useLocation();


  // get Blogs Api

  const getBlogsApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getBlogs}`);
      if (res?.data?.success) {
        setBlogs(res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getBlogsApi();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Award-Winning Interior Design in India | The City Interior
        </title>
        <meta
          name="description"
          content="The City Interior offers residential & commercial design solutions across India. Get a free quote or call us today: +919871206986"
        />
        <link rel="canonical" href={`${liveDomain}${location.pathname}`} />
      </Helmet>
      <PageLayout>
        {loader ? (
          <LoaderCard />
        ) : (
          <Fragment>
            <TitleStrip title="Blogs" />
            <BlogList blogData={blogs} />
          </Fragment>
        )}
      </PageLayout>
    </Fragment>
  );
};
export default Blogs;
