import { createContext, useState } from "react";

export const webContext = createContext();

const WebContext = ({children}) => {
    const [category, setCategory] = useState([]);
    return(
        <webContext.Provider value={{category, setCategory}}>
            {children}
        </webContext.Provider>
    )
}

export default WebContext;