import { useNavigate } from "react-router-dom";
import "./PortfolioList.scss";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { baseUrl } from "../../../utils/apiData";

const PortfolioList = ({ portfolioData }) => {
  const navigate = useNavigate();
  return (
    <section className="list_grid p_t">
      <div className="content_wrap">
        {portfolioData?.length > 0 ? (
          <div className="portfolio_grid">
            {portfolioData?.map((data) => {
              return (
                <div
                  className="portfolio_card"
                  key={data?.id}
                  onClick={() => {
                    navigate(`/portfolio/${data?.slug}`);
                  }}
                >
                  <img
                    src={`${baseUrl}/${data?.image}`}
                    alt="interior"
                    loading="lazy"
                  />
                  <p>
                    {data?.name} <HiOutlineArrowLongRight />
                  </p>
                </div>
              );
            })}
          </div>
        ) : (
          <p>Data Not Found...</p>
        )}
      </div>
    </section>
  );
};

export default PortfolioList;
