export const liveDomain = "https://www.thecityinterior.com";

export const baseUrl = "https://city.supagrow.in";


export const getHomePage = "api/gethomepage";
export const getAboutPage = "api/getaboutpage";

export const getBlogs = "api/getblogs";
export const getBlogDetail = "api/blogdetails";

export const getPortfolio = "api/getportfolio";
export const portfolioDetail = "api/portfoliodetails";

export const getContactPage = "api/getcontactpage";
export const storeContact = "api/storecontactapi";

export const getCategory = "api/getcategory";
// export const getServiceByCategory = "api/getservicebycategory";
export const serviceProductDetail = "api/serviceproductdetails";


