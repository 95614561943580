import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialMenuState = { status: false };

const menuSlice = createSlice({
  name: "menuStatus",
  initialState: initialMenuState,
  reducers: {
    menuChanger(state, action) {
      state.status = action.payload;
    },
   
  },
});


const store = configureStore({
    reducer:{menu:menuSlice.reducer}
});

export const menuActions = menuSlice.actions;


export default store;