import "./WhyChooseUs.scss";
import Marquee from "react-fast-marquee";
import { motion } from "framer-motion";
import { baseUrl } from "../../../utils/apiData";

const WhyChooseUs = ({ dataList, title }) => {
  return (
    <section className="why_choose_us">
      <div className="content_wrap p_t p_b">
        <motion.h2
          initial={{ scaleY: 0, opacity: 0 }}
          whileInView={{ scaleY: 1, opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 0.6,
          }}
          className="title_header"
        >
         {title}
        </motion.h2>
        <Marquee>
          {[...dataList, ...dataList]?.map((item, j) => {
            return (
              <div className="marque_box" key={j}>
                <div className="content">
                  <div className="icon_wrap">
                    <div className="shape"></div>
                    <img src={`${baseUrl}/${item?.choose_image}`} alt="Icon" />
                  </div>
                  <p>{item.choose_title}</p>
                </div>
              </div>
            );
          })}
        </Marquee>
      </div>
    </section>
  );
};

export default WhyChooseUs;
