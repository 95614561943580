import "./PortfolioDetails.scss";
import PageLayout from "../../layout/pagelayout/PageLayout";
import { images } from "../../utils/images";
import { useNavigate, useParams } from "react-router-dom";
import PortfolioDetailLanding from "./portfolioDetailLanding/PortfolioDetailLanding";
import PortfolioInfo from "./portfolioInfo/PortfolioInfo";
import HomeCta from "../../components/homecta/HomeCta";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { baseUrl, liveDomain, portfolioDetail } from "../../utils/apiData";
import { useLocation } from "react-router-dom";
import LoaderCard from "../../components/loaderCard/LoaderCard";
import axios from "axios";
const PortfolioDetails = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});

  const location = useLocation();
  const { slug } = useParams();

  const getPortfolioDetailApi = async () => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${portfolioDetail}`, {
        slug: `${slug}`,
      });
      if (res?.data?.success) {
        setData(res?.data?.data);
        
      }
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getPortfolioDetailApi();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Award-Winning Interior Design in India | The City Interior
        </title>
        <meta
          name="description"
          content="The City Interior offers residential & commercial design solutions across India. Get a free quote or call us today: +919871206986"
        />
        <link rel="canonical" href={`${liveDomain}${location.pathname}`} />
      </Helmet>
      <PageLayout>
        {loader ? (
          <LoaderCard />
        ) : (
          <Fragment>
            <PortfolioDetailLanding img={`${baseUrl}/${data?.image}`} />
            <PortfolioInfo data={data} />
            <HomeCta
              title="Contact Us"
              text="Schedule a free consultation with our expert designers"
              banner={images.homeCta}
              btnText="Get In Touch"
              clickHandler={() => navigate("/contact")}
            />
          </Fragment>
        )}
      </PageLayout>
    </Fragment>
  );
};

export default PortfolioDetails;
