import PageLayout from "../../layout/pagelayout/PageLayout";
import ContactLanding from "./contactLanding/ContactLanding";
import ContactInfo from "./contactInfo/ContactInfo";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { baseUrl, getContactPage, liveDomain } from "../../utils/apiData";
import { useLocation } from "react-router-dom";
import axios from "axios";
import LoaderCard from "../../components/loaderCard/LoaderCard";
const Contact = () => {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});
  const location = useLocation();

  // Get Contact Page API
  const getContactPageApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getContactPage}`);
      if (res?.data?.success) {
        setData(res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getContactPageApi();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Award-Winning Interior Design in India | The City Interior
        </title>
        <meta
          name="description"
          content="The City Interior offers residential & commercial design solutions across India. Get a free quote or call us today: +919871206986"
        />
        <link rel="canonical" href={`${liveDomain}${location.pathname}`} />
      </Helmet>
      <PageLayout>
        {loader ? (
          <LoaderCard />
        ) : (
          <Fragment>
            <ContactLanding data={data} />
            <ContactInfo data={data} />
            <div className="filler p_t"></div>
          </Fragment>
        )}
      </PageLayout>
    </Fragment>
  );
};

export default Contact;
