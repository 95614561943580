import "./ServiceList.scss";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../utils/apiData";

const ServiceList = ({ tab, data }) => {

  const navigate = useNavigate();


  return (
    <section className="service_list_sec">
      <div className="content_wrap">
        <div className="portfolio_grid">
          {
            data?.find(ele => ele?.category?._id ===  tab)?.services?.length > 0 ? 
            data?.find(ele => ele?.category?._id ===  tab)?.services?.map((item) => {
              return (
                <div
                  className="service_card"
                  key={item?._id}
                  onClick={() => navigate(`/services/${item?.slug}`)}
                >
                  <img src={`${baseUrl}/${item?.image}`} alt="interior" loading="lazy" />
                  <p>
                    {item?.title} <HiOutlineArrowLongRight />
                  </p>
                </div>
              );
            })
             : <p className="text-center py-5">Service not found...</p>
          }        
        </div>
      </div>
    </section>
  );
};

export default ServiceList;
