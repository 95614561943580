import "./PortfolioInfo.scss";

const PortfolioInfo = ({ data }) => {
  return (
    <section className="portfolio_info">
      <div className="content_wrap p_t p_b">
        <div className="info">
          <h1 className="pb-4">{data?.name}</h1>
          <div className="left_info">
            {data?.contractor && (
              <div className="info_wrap">
                <p className="t_14">Contractor</p>
                <p>{data?.contractor}</p>
              </div>
            )}

            {data?.architech && (
              <div className="info_wrap">
                <p className="t_14">Architech</p>
                <p>{data?.architech}</p>
              </div>
            )}

            {data?.in_designer && (
              <div className="info_wrap">
                <p className="t_14">Interior Designer</p>
                <p>{data?.in_designer}</p>
              </div>
            )}

            {data?.project_management && (
              <div className="info_wrap">
                <p className="t_14">Project Management</p>
                <p>{data?.project_management}</p>
              </div>
            )}

            {data?.partition_system && (
              <div className="info_wrap">
                <p className="t_14">Partition System</p>
                <p>{data?.partition_system}</p>
              </div>
            )}

            {data?.door_system && (
              <div className="info_wrap">
                <p className="t_14">Door System</p>
                <p>{data?.door_system}</p>
              </div>
            )}
          </div>
        </div>
        <div
          className="right_info"
          dangerouslySetInnerHTML={{ __html: data?.content }}
        ></div>
      </div>
    </section>
  );
};

export default PortfolioInfo;
