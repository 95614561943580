import "./Portfolio.scss";
import PageLayout from "../../layout/pagelayout/PageLayout";
import ImageTitleStrip from "../../components/imageTitleStrip/ImageTitleStrip";
import PortfolioList from "./portfolioList/PortfolioList";
import TriNavigate from "../../components/trinavigate/TriNavigate";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { baseUrl, getPortfolio, liveDomain } from "../../utils/apiData";
import { useLocation } from "react-router-dom";
import LoaderCard from "../../components/loaderCard/LoaderCard";
import axios from "axios";

const Portfolio = () => {
  const [loader, setLoader] = useState(false);
  const [portfolioList, setPortfolioList] = useState([]);
  const [data, setData] = useState({});
  const location = useLocation();



  const getPortfolioAPi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getPortfolio}`);
      if (res?.data?.success) {
        setPortfolioList(res?.data?.data?.portfolio);
        setData(res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getPortfolioAPi();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Award-Winning Interior Design in India | The City Interior
        </title>
        <meta
          name="description"
          content="The City Interior offers residential & commercial design solutions across India. Get a free quote or call us today: +919871206986"
        />
        <link rel="canonical" href={`${liveDomain}${location.pathname}`} />
      </Helmet>
      <PageLayout>
        {loader ? (
          <LoaderCard />
        ) : (
          <Fragment>
            <ImageTitleStrip
              title="Portfolio"
              name="Portfolio"
              type="Home"
              img={`${baseUrl}/${data?.hero_section_image}`}
            />
            <PortfolioList portfolioData={portfolioList} />
            <div className="p_b"></div>
            <TriNavigate
              imgA={`${baseUrl}/${data?.about_image}`}
              imgB={`${baseUrl}/${data?.blog_image}`}
              imgC={`${baseUrl}/${data?.contact_image}`}
              titleA="about"
              titleB="Blogs"
              titleC="Contact"
              pathA="/about"
              pathB="/blogs"
              pathC="/contact"
            />
          </Fragment>
        )}
      </PageLayout>
    </Fragment>
  );
};
export default Portfolio;
