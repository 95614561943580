import "./AboutLanding.scss";
import { images } from "../../../utils/images";
import { BsArrowDown } from "react-icons/bs";
import { motion } from "framer-motion";
import { baseUrl } from "../../../utils/apiData";

const AboutLanding = ({aboutData}) => {
  const goSecondSec = () => {
    const section = document.getElementById("aboutlr");

    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="about_landing">
      <div className="content_wrap">
        <div className="white_flap"></div>
        <div className="text">
          <motion.p
            initial={{ scaleY: 0, opacity: 0 }}
            whileInView={{ scaleY: 1, opacity: 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
              delay: 0.2,
            }}
          >
           <b>About Us</b>
          </motion.p>
          <motion.h1
            initial={{ scaleY: 0, opacity: 0 }}
            whileInView={{ scaleY: 1, opacity: 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
              delay: 0.3,
            }}
          >
         {aboutData?.title || "-"}
          </motion.h1>
          <button type="button" onClick={goSecondSec}>
            <b>Read more <BsArrowDown /></b>
          </button>
        </div>

        <div className="img_wrap">
          <img src={`${baseUrl}/${aboutData?.image}`} alt="about" loading="lazy" />
        </div>
      </div>
    </section>
  );
};

export default AboutLanding;
