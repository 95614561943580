import { useNavigate } from "react-router-dom";
import ArrowBtn from "../../components/arrowbtn/ArrowBtn";
import "./ErrorPage.scss";
import PageLayout from "../../layout/pagelayout/PageLayout";

const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <PageLayout>
      <section className="error_page">
        <div className="content_wrap p_t p_b">
          <h1>404</h1>
          <h3>Sorry! Page not found</h3>
          <p>
            This page doesn't exist or was removed! We suggest you go back to
            homepage.
          </p>
          <ArrowBtn
            text="Home"
            clickHandler={() => {
              navigate("/");
            }}
          />
        </div>
      </section>
    </PageLayout>
  );
};

export default ErrorPage;
