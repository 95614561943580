import { Fragment, useContext, useEffect, useState } from "react";
import "./Header.scss";
import { LiaAngleDownSolid } from "react-icons/lia";
import { images } from "../../utils/images";
import { useDispatch, useSelector } from "react-redux";
import { menuActions } from "../../store/index";
import SubServicesSlider from "./subServiceSlider/SubServicesSlider";
import ArrowBtn from "../../components/arrowbtn/ArrowBtn";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl, getCategory } from "../../utils/apiData";
import { webContext } from "../../utils/WebContext";

const Header = ({ isScrolled }) => {
  const [categoryList, setCategoryList] = useState([]);
  const [currentId, setCurrentId] = useState("");
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const [strip, setStrip] = useState(50);

  const { status } = useSelector((state) => state?.menu);
  const dispatch = useDispatch();

  // Get Category API
  const getCategoryApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getCategory}`);
      if (res?.data?.success) {
        setCategoryList(res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getCategoryApi();
  }, []);
  return (
    <header style={{ transform: `scaleY(${isScrolled ? "0" : "1"})` }}>
      <div className="decta_grid">
        <div
          className="bg_strip"
          style={{ width: `${currentId ? 100 : 50}%` }}
        ></div>

        <div className="square"></div>

        <div className="square">
          <a href="/" className="logo">
            <img
              className="logo"
              src={images.logoLight}
              alt="BrandLogo"
              loading="lazy"
            />
          </a>
        </div>

        <div className="square"></div>

        <div className="square">
          <button
            type="button"
            className={`t_14 ${!currentId ? "white" : ""} ${
              currentId === categoryList?.[0]?.category?._id ? "active" : ""
            }`}
            onMouseEnter={() => setCurrentId(categoryList?.[0]?.category?._id)}
          >
            {categoryList?.[0]?.category?.category_name} <LiaAngleDownSolid />
          </button>
        </div>

        <div className="square">
          <button
            type="button"
            className={`t_14 ${
              currentId === categoryList?.[1]?.category?._id ? "active" : ""
            }`}
            onMouseEnter={() => setCurrentId(categoryList?.[1]?.category?._id)}
          >
            {categoryList?.[1]?.category?.category_name} <LiaAngleDownSolid />
          </button>
        </div>

        <div className="square">
          <button
            type="button"
            className={`t_14 ${
              currentId === categoryList?.[2]?.category?._id ? "active" : ""
            }`}
            onMouseEnter={() => setCurrentId(categoryList?.[2]?.category?._id)}
          >
            {categoryList?.[2]?.category?.category_name} <LiaAngleDownSolid />
          </button>
        </div>

        <div className="square">
          <button
            type="button"
            className={`t_14 ${
              currentId === categoryList?.[3]?.category?._id ? "active" : ""
            }`}
            onMouseEnter={() => setCurrentId(categoryList?.[3]?.category?._id)}
          >
            {categoryList?.[3]?.category?.category_name} <LiaAngleDownSolid />
          </button>
        </div>

        <div className="square">
          <button
            type="button"
            className="t_14 menubtn"
            onClick={() => dispatch(menuActions.menuChanger(true))}
          >
            <p className="t_14">
              <span>Menu</span>
            </p>
          </button>
        </div>
      </div>

      <div className={`menu_dd ${currentId ? "active" : ""}`}>
        {currentId && (
          <SubServicesSlider
            category={
              categoryList?.find((el) => el?.category?._id === currentId)
                ?.category?.category_name
            }
            list={
              categoryList?.find((el) => el?.category?._id === currentId)
                ?.services
            }
            categoryId={currentId}
            onLeave={() => {
              setCurrentId("");
              setStrip(50);
            }}
          />
        )}
      </div>

      {/* Small Screen Menu */}
      <section className="small_menu">
        <div className="content_wrap">
          <a href="/">
            <img src={images.logoDark} alt="Brand Logo" loading="lazy" />
          </a>
          <ArrowBtn
            text="Explore Services"
            clickHandler={() => navigate("/services")}
          />
        </div>
      </section>
    </header>
  );
};
export default Header;
