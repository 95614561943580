import "./ServicesLanding.scss";
import { images } from "../../../utils/images";
import { motion } from "framer-motion";

const ServicesLanding = ({ tab, setTab, data }) => {
  const setTabHandler = (num) => {
    setTab(num);
  };

  return (
    <section className="service_title_strip">
      <img
        src={images.InteriorA}
        alt="services"
        className="bg"
        loading="lazy"
      />
      <div className="content_wrap">
        <motion.h1
          initial={{ translateX: 150, opacity: 0 }}
          whileInView={{ translateX: 0, opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 0.8,
            delay: 0.2,
          }}
        >
          Services
        </motion.h1>
        <div className="tab_wraps">
          {data?.length > 0 && data?.map((item) => {
            return (
              <button
                type="button"
                className={tab === item?.category?._id ? "active" : ""}
                onClick={() => setTabHandler(item?.category?._id)}
                key={item?.category?._id}
              >
                <span></span>
                {item?.category?.category_name || "-"}
              </button>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ServicesLanding;
