import "./Footer.scss";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa6";
import { images } from "../../utils/images";
import { Fragment, useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { webContext } from "../../utils/WebContext";
import axios from "axios";
import { baseUrl, getCategory } from "../../utils/apiData";

const Footer = () => {
  const [categoryList, setCategoryList] = useState([]);

  // Get Category API
  const getCategoryApi = async () => {
    try {
      const res = await axios.get(`${baseUrl}/${getCategory}`);
      if (res?.data?.success) {
        setCategoryList(res?.data?.data);
        console.log("category", res?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const company = [
    {
      id: 0,
      text: "Home",
      path: "/",
    },
    {
      id: 1,
      text: "About",
      path: "/about",
    },

    {
      id: 11,
      text: "Services",
      path: "/services",
    },

    {
      id: 2,
      text: "Portfolio",
      path: "/portfolio",
    },

    {
      id: 3,
      text: "Blogs",
      path: "/blogs",
    },
    {
      id: 4,
      text: "Contact",
      path: "/contact",
    },
  ];

  useEffect(() => {
    getCategoryApi();
  }, []);

  return (
    <footer>
      <section className="footer">
        <div className="content_wrap">
          <div className="grid_wrap">
            <div className="link_col">
              <img src={images.logoDark} alt="Brand Logo" loading="lazy" />
            </div>

            <div className="link_col">
              <p className="title">Company</p>
              {company?.map((data) => {
                return (
                  <NavLink to={data.path} key={data.id}>
                    {data.text}
                  </NavLink>
                );
              })}
            </div>

            {categoryList?.filter(ele => ele?.services?.length > 0)?.map((data, k) => {
              return (
                <div className="link_col" key={k}>
                  <p className="title">Residential Interiors</p>
                  {data?.services?.map((item) => {
                    return (
                      <Fragment key={item?._id}>
                        <NavLink to={`/services/${item?.slug}`}>
                          {item?.title}
                        </NavLink>
                      </Fragment>
                    );
                  })}
                </div>
              );
            })}

         
          </div>

          <div className="lower_line">
            <div className="social">
              <a href="" target="_blanlk">
                <FaInstagram />
              </a>
              <a href="" target="_blanlk">
                <FaLinkedinIn />
              </a>
            </div>
          </div>

          <div className="bottom_line">
            <p>Designed and Developed by Supagrow {new Date().getFullYear()}</p>
          </div>
        </div>
      </section>
    </footer>
  );
};
export default Footer;
