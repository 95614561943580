import "./SubServicesSlider.scss";

import { HiOutlineArrowLongRight } from "react-icons/hi2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl, getServiceByCategory } from "../../../utils/apiData";
import { Fragment, useEffect } from "react";

const SubServicesSlider = ({
  category,
  categoryId,
  list,
  isService,
  onLeave,
}) => {
  const navigate = useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <section className="services_slider" onMouseLeave={onLeave}>
      <div className="content_wrap">
        {list?.length > 0 ? (
          <Fragment>
            {list?.length > 1 && (
              <Slider {...settings}>
                {list?.map((data) => {
                  return (
                    <div
                      className="service_slide"
                      key={data?._id}
                      onClick={() => navigate(`/services/${data.slug}`)}
                    >
                      <div className="img_wrap">
                        <img
                          src={`${baseUrl}/${data?.image}`}
                          alt="interior"
                          loading="lazy"
                        />
                      </div>
                      <p>
                        {data?.title} <HiOutlineArrowLongRight />
                      </p>
                    </div>
                  );
                })}
              </Slider>
            )}

            {list?.length === 1 && (
              <div className="four_grid">
                <div
                  className="service_slide"
                  onClick={() => navigate(`/services/test`)}
                >
                  <div className="img_wrap">
                    <img
                      src={list[0]?.img}
                      alt="Interior Service"
                      loading="lazy"
                    />
                  </div>
                  <p>
                    {list[0]?.text} <HiOutlineArrowLongRight />
                  </p>
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <p className="text-center py-5">Service does not found...</p>
        )}
      </div>
    </section>
  );
};

export default SubServicesSlider;
