import "./OneStopShop.scss";
import ArrowBtn from "../../../components/arrowbtn/ArrowBtn";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { useRef } from "react";
import Slider from "react-slick";
import { images } from "../../../utils/images";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { baseUrl } from "../../../utils/apiData";

const OneStopShop = ({homeData, dataList}) => {
  const navigate = useNavigate();

  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <section className="latest_one_shop">
      <div className="content_wrap p_t p_b">
        <motion.h1
          initial={{ scaleY: 0, opacity: 0 }}
          whileInView={{ scaleY: 1, opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 0.6,
          }}
        >
     {homeData?.service_title}
        </motion.h1>
        <motion.p
          initial={{ scaleY: 0, opacity: 0 }}
          whileInView={{ scaleY: 1, opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 0.6,
          }}
        >
      {homeData?.service_description}
        </motion.p>
        <div className="shop_slider">
          <Slider ref={sliderRef} {...settings}>
            {dataList?.map((data) => {
              return (
                <div className="card_slide" key={data?._id}>
                  <div className="img_wrap">
                    <img src={`${baseUrl}/${data?.image}`} alt="portfolio" loading="lazy" />
                  </div>
                  <div className="text">
                    <h5>{data.title}</h5>
                    <div className="detail" dangerouslySetInnerHTML={{ __html: data?.description }} />
                    <ArrowBtn
                      text="Read More"
                      clickHandler={() => navigate(`services/${data?.slug}`)}
                    />
                  </div>
                </div>
              );
            })}
          </Slider>

          <button
            type="button"
            className="controle_btn prev"
            onClick={handlePrev}
          >
            <IoIosArrowBack />
          </button>
          <button
            type="button"
            className="controle_btn next"
            onClick={handleNext}
          >
            <IoIosArrowForward />
          </button>
        </div>
      </div>
    </section>
  );
};

export default OneStopShop;
