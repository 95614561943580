import { Fragment, useEffect, useState } from "react";
import TriNavigate from "../../components/trinavigate/TriNavigate";
import PageLayout from "../../layout/pagelayout/PageLayout";
import { images } from "../../utils/images";
import "./About.scss";
import AboutLanding from "./aboutLanding/AboutLanding";
import AboutLRT from "./aboutlrt/AboutLRT";
import Testimonials from "./testimonials/Testimonials";
import { Helmet } from "react-helmet";
import { baseUrl, getAboutPage, liveDomain } from "../../utils/apiData";
import { useLocation } from "react-router-dom";
import axios from "axios";
import LoaderCard from "../../components/loaderCard/LoaderCard";

const About = () => {
  const [loader, setLoader] = useState(false);
  const [aboutData, setAboutData] = useState({});
  const [testimonial, setTestimonial] = useState([]);
  const location = useLocation();

  const getAboutApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getAboutPage}`);
      if (res?.data?.success) {
        setAboutData(res?.data?.data?.aboutpage);
        setTestimonial(res?.data?.data?.testimonial);
      }
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getAboutApi();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Award-Winning Interior Design in India | The City Interior
        </title>
        <meta
          name="description"
          content="The City Interior offers residential & commercial design solutions across India. Get a free quote or call us today: +919871206986"
        />
        <link rel="canonical" href={`${liveDomain}${location.pathname}`} />
      </Helmet>
      <PageLayout>
        {loader ? (
          <LoaderCard />
        ) : (
          <Fragment>
            <AboutLanding aboutData={aboutData} />
            <AboutLRT
              title={aboutData?.section_1_title}
              des={aboutData?.section_1_description}
            />
            <Testimonials dataList={testimonial} />
            <TriNavigate
              imgA={`${baseUrl}/${aboutData?.blog_image}`}
              imgB={`${baseUrl}/${aboutData?.portfolio_image}`}
              imgC={`${baseUrl}/${aboutData?.contact_image}`}
              titleA="Blogs"
              titleB="Portfolio"
              titleC="Contact"
              pathA="/blogs"
              pathB="/portfolio"
              pathC="/contact"
            />
          </Fragment>
        )}
      </PageLayout>
    </Fragment>
  );
};

export default About;
