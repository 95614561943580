import "./ArrowBtn.scss";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { motion } from "framer-motion";

const ArrowBtn = ({ text, clickHandler, classList, type, delayed }) => {
  return (
    <motion.span
    initial={{ scaleY: 0, opacity: 0 }}
    whileInView={{ scaleY: 1, opacity: 1 }}
    transition={{
      ease: "easeInOut",
      duration: 0.6,
      delay: delayed || 0.2,
    }}
    >
    <button
   

      type={type ? type : "button"}
      onClick={clickHandler}
      className={`orange_btn ${classList}`}
    >
      <span className="left">
        <MdOutlineArrowRightAlt />
      </span>
      {text}

      <span className="right">
        <MdOutlineArrowRightAlt />
      </span>
      <div className="line"></div>
      <div className="filler"></div>
    </button>
    </motion.span>
  );
};

export default ArrowBtn;
