import "./BlogInfo.scss";

const BlogInfo = ({data}) => {
    return(
        <section className="blog_info">
            <div className="content_wrap p_t p_b">
                <div className="info">
                    <h1 className="pb-4">{data?.name}</h1>
            
                </div>
                <div className="right_info" dangerouslySetInnerHTML={{ __html: data?.content }}>
                </div>
            </div>
        </section>
    )
}

export default BlogInfo;