import "./ContactInfo.scss";
import { useState } from "react";
import { images } from "../../../utils/images";
import { Parallax } from "react-scroll-parallax";
import { motion } from "framer-motion";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import TextMessage from "./textmessage/TextMessage";
import { baseUrl, storeContact } from "../../../utils/apiData";
import axios from "axios";
import { IoMdClose } from "react-icons/io";

const ContactInfo = ({ data }) => {
  const [loader, setLoader] = useState(false);
  const [success, setSuccess] = useState(false);

  const initialValues = {
    name: "",
    email: "",
    phone: "",
    message: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Field is required!"),
    email: Yup.string()
      .email("Invalid email formate!")
      .required("Email is required!"),
    phone: Yup.string().required("Field is required!"),
    message: Yup.string().required("Field is required!"),
  });

  // Form Submit API
  const formSubmitApi = async (body) => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${storeContact}`, { ...body });
      if (res?.data?.success) {
        setSuccess(true);
      }
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };

  const onSubmit = (values, { resetForm }) => {
    formSubmitApi(values);
    resetForm();
  };

  return (
    <section className="contact_info">
      <div className="content_wrap">
        <div className="left">
          <motion.p
            initial={{ scaleY: 0, opacity: 0 }}
            whileInView={{ scaleY: 1, opacity: 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
              delay: 0.2,
            }}
            className="t_24"
          >
            {data?.title}
          </motion.p>

          {success ? (
            <div className="success_screen">
              <button
                type="button"
                className="close"
                onClick={() => setSuccess(false)}
              >
                <IoMdClose />
              </button>
              <h2 className="title_header">Thanks...</h2>
              <p className="m-0">Form Submit Successfully.</p>
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <h2 className="title">Message</h2>

                <div className="form_field">
                  <label>Name</label>
                  <Field
                    type="text"
                    placeholder="Name"
                    id="name"
                    name="name"
                    autoComplete="off"
                  />
                  <ErrorMessage name="name" component={TextMessage} />
                </div>

                <div className="form_field">
                  <label>Email</label>
                  <Field
                    type="email"
                    placeholder="Email"
                    id="email"
                    name="email"
                    autoComplete="off"
                  />
                  <ErrorMessage name="email" component={TextMessage} />
                </div>

                <div className="form_field">
                  <label>Phone</label>
                  <Field
                    type="number"
                    placeholder="Phone"
                    id="phone"
                    name="phone"
                    autoComplete="off"
                  />
                  <ErrorMessage name="phone" component={TextMessage} />
                </div>

                <div className="form_field">
                  <label>Message</label>
                  <Field
                    as="textarea"
                    type="text"
                    id="message"
                    placeholder="Message"
                    name="message"
                    autoComplete="off"
                  />
                  <ErrorMessage name="message" component={TextMessage} />
                </div>

                {loader ? (
                  <button type="button">Loading...</button>
                ) : (
                  <button type="submit">Submit</button>
                )}
              </Form>
            </Formik>
          )}
        </div>
        <div className="right">
          <div className="img_wrap">
            <Parallax speed={10}>
              <div className="circle"></div>
            </Parallax>
            <img
              src={`${baseUrl}/${data?.image}`}
              alt="contact"
              className="verticle"
              loading="lazy"
            />
            <img
              src={images.blogA}
              alt="contact"
              className="second"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
