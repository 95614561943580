import "./AboutLRT.scss";
import { motion } from "framer-motion";

const AboutLRT = ({title, des}) => {
  return (
    <section className="about_lrt p_t p_b" id="aboutlr">
      <div className="content_wrap">
        <motion.h2
          initial={{ scaleY: 0, opacity: 0 }}
          whileInView={{ scaleY: 1, opacity: 1 }}
          transition={{
            ease: "easeInOut",
            duration: 0.6,
            delay: 0.2,
          }}
          className="title_header"
        >
          {title || "-"}
        </motion.h2>
        <div className="para">
          <motion.p
             initial={{ scaleY: 0, opacity: 0 }}
               whileInView={{ scaleY: 1, opacity: 1 }}
               transition={{
                 ease: "easeInOut",
                 duration: 0.6,
                 delay: 0.2,
               }}
          >
       {des || "-"}
          </motion.p>
      
        </div>
      </div>
    </section>
  );
};

export default AboutLRT;
