import "./BlogList.scss";
import ArrowBtn from "../../../components/arrowbtn/ArrowBtn";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../utils/apiData";

const BlogList = ({ blogData }) => {
  const navigate = useNavigate();

  const formatedDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = String(date.getDate()).padStart(2, "0");
    const monthName = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${monthName} ${year}`;
  };

  return (
    <section className="blogs">
      {blogData?.length > 0 ? (
        <div className="content_wrap">
          {blogData?.map((data) => {
            return (
              <div
                className="blog_card"
                key={data?._id}
                onClick={() => navigate(`/blogs/${data?.slug}`)}
              >
                <div className="img_wrap">
                  <img
                    src={`${baseUrl}/${data?.image}`}
                    alt="blog"
                    loading="lazy"
                  />
                </div>
                <div className="text_view">
                  <p className="t_14"><b>{formatedDate(data?.updatedAt)}</b></p>
                  <h3>{data?.name}</h3>
                  <p>{data?.short_content}</p>
                  <ArrowBtn text="Read Article" />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <p className="text-center py-5 my-5">Data not found...</p>
      )}
    </section>
  );
};

export default BlogList;
