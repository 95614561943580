import { images } from "../../utils/images";
import PageLayout from "../../layout/pagelayout/PageLayout";
import LatestHomeLanding from "./latestHomeLanding/LatestHomeLanding";
import OneStopShop from "./oneStopShop/OneStopShop";
import WhyChooseUs from "./whyChoose/WhyChooseUs";
import TriGridInfo from "./triGridInfo/TriGridInfo";
import HomeCta from "../../components/homecta/HomeCta";
import { useLocation, useNavigate } from "react-router-dom";
import Glimps from "./glimps/Glimps";
import { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { baseUrl, getCategory, getHomePage, liveDomain } from "../../utils/apiData";
import axios from "axios";
import LoaderCard from "../../components/loaderCard/LoaderCard";

const LatestHome = () => {
  const [loader, setLoader] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [homeData, setHomeData] = useState({});
  const [choose, setChoose] = useState([]);
  const [homeSlide, setHomeSlide] = useState([]);
  const [slide, setSlide] = useState([]);

  const navigate = useNavigate();
  const secRef = useRef();
  const goToTarget = () => {
    secRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const location = useLocation();

  // Get Home PAge API

  const getHomePageApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getHomePage}`);
      if (res?.data?.success) {
        setHomeSlide(res?.data?.data?.homeslide);
        setHomeData(res?.data?.data?.home);
        setChoose(res?.data?.data?.choose);
        setSlide(res?.data?.data?.slide);
      }
    } catch (error) {
      console.error(error);
    }
    setLoader(false);
  };



    // Get Category API
    const getCategoryApi = async () => {
      setLoader(true);
      try {
        const res = await axios.get(`${baseUrl}/${getCategory}`);
        if (res?.data?.success) {
          setCategoryList(res?.data?.data?.map(ele => ele?.services).flat());
        }
      } catch (error) {
        console.error(error);
      }
      setLoader(false);
    };

  useEffect(() => {
    getHomePageApi();
    getCategoryApi();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Award-Winning Interior Design in India | The City Interior
        </title>
        <meta
          name="description"
          content="The City Interior offers residential & commercial design solutions across India. Get a free quote or call us today: +919871206986"
        />
        <link rel="canonical" href={`${liveDomain}/${location.pathname}`} />
      </Helmet>

      <PageLayout>
        {loader ? (
          <LoaderCard />
        ) : (
          <Fragment>
            <LatestHomeLanding dataList={slide} goToTarget={goToTarget} />
            <OneStopShop homeData={homeData} dataList={categoryList} />
            <WhyChooseUs dataList={choose} title={homeData?.why_choose_us_title || "-"} />
            <TriGridInfo homeData={homeData} />
            <div ref={secRef}>
              <Glimps dataList={homeSlide} homeData={homeData} />
            </div>
            <HomeCta
              title="Contact Us"
              text="Schedule a free consultation with our expert designers"
              banner={images.homeCta}
              btnText="Get In Touch"
              clickHandler={() => navigate("/contact")}
            />
          </Fragment>
        )}
      </PageLayout>
    </Fragment>
  );
};

export default LatestHome;
