import "./HomeCta.scss";
import ArrowBtn from "../arrowbtn/ArrowBtn";
import { motion } from "framer-motion";

const HomeCta = ({ title, text, banner, btnText, clickHandler }) => {
  return (
    <section className="home_cta">
      <img src={banner} alt="interior" loading="lazy" />
      <div className="overlay"></div>
      <motion.p
        initial={{ scaleY: 0, opacity: 0 }}
        whileInView={{ scaleY: 1, opacity: 1 }}
        transition={{
          ease: "easeInOut",
          duration: 0.6,
          delay: 0.2,
        }}
        className="title"
      >
        {title}
      </motion.p>
      <motion.p
        initial={{ scaleY: 0, opacity: 0 }}
        whileInView={{ scaleY: 1, opacity: 1 }}
        transition={{
          ease: "easeInOut",
          duration: 0.6,
          delay: 0.4,
        }}
        className="text"
      >
        {text}
      </motion.p>
      <ArrowBtn classList="dark" text={btnText} clickHandler={clickHandler} />
    </section>
  );
};

export default HomeCta;
