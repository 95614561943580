import { NavLink } from "react-router-dom";
import "./MenuScreen.scss";
import { images } from "../../utils/images";

import { menuActions } from "../../store";
import { useDispatch } from "react-redux";
import { IoCloseOutline } from "react-icons/io5";

import { motion } from "framer-motion";

const MenuScreen = () => {
  const menuScreenData = [
    {
      id: 0,
      text: "Home",
      path: "/",
    },
    {
      id: 1,
      text: "About",
      path: "/about",
    },
    {
      id: 11,
      text: "Services",
      path: "/services",
    },

    {
      id: 2,
      text: "Portfolio",
      path: "/portfolio",
    },

    {
      id: 3,
      text: "Blogs",
      path: "/Blogs",
    },
    {
      id: 4,
      text: "Contact",
      path: "/contact",
    },
  ];
  const dispatch = useDispatch();

  const menuClose = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
    dispatch(menuActions.menuChanger(false));
  };

  return (
    <section className="menu_screen">
      <div className="img_cover">
        <div className="layer"></div>
        <img src={images.acousticA} alt="interior" loading="lazy" />
      </div>
      <div className="strip_grid">
        <div className="flap"></div>
        <div className="flap"></div>
        <div className="flap"></div>
        <div className="flap"></div>
        <div className="flap"></div>
        <div className="flap"></div>
        <div className="flap"></div>
        <div className="flap"></div>
      </div>

      <div className="content_wrap">
        <button type="button" className="close_btn" onClick={menuClose}>
          <IoCloseOutline />
        </button>
        <div className="bi_grid">
          <div className="link_wrap">
            {menuScreenData.map((data, j) => {
              return (
                <NavLink to={data.path} onClick={menuClose} key={data.id}>
                  <motion.h1
                    initial={{ scaleY: 0, opacity: 0 }}
                    whileInView={{ scaleY: 1, opacity: 1 }}
                    transition={{
                      ease: "easeInOut",
                      duration:0.4,
                      delay: 0.6,
                    }}
                  >
                    {data.text}
                  </motion.h1>
                </NavLink>
              );
            })}
          </div>
          <div className="empty"></div>
        </div>
      </div>
    </section>
  );
};
export default MenuScreen;
