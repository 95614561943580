import "./TriGridInfo.scss";
import { motion } from "framer-motion";
import ArrowBtn from "../../../components/arrowbtn/ArrowBtn";
import { useNavigate } from "react-router-dom";
import {baseUrl} from "../../../utils/apiData";
  
const TriGridInfo = ({homeData}) => {
  const navigate = useNavigate();

  const dataList = [
    {
      id: 0,
      title:homeData?.section_1_title,
      text:homeData?.section_1_description,
      icon:homeData?.section_1_image,
      link: "",
    },
    {
      id: 1,
      title:homeData?.section_2_title,
      text:homeData?.section_2_description,
      icon:homeData?.section_2_image,
      link: "/",
    },
    {
      id: 2,
      title:homeData?.section_3_title,
      text:homeData?.section_3_description,
      icon:homeData?.section_3_image,
      link: "/",
    },
  ];

  return (
    <section className="tri_sec_grid_info">
      <div className="content_wrap p_t p_b">
        <div className="title_wrap">
          {/* Kitchen Wardrobe Full Home */}
          <motion.h2
            initial={{ scaleY: 0, opacity: 0 }}
            whileInView={{ scaleY: 1, opacity: 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
            }}
            className="title_header"
          >
            {homeData?.sec_1_heading || "-"}
          </motion.h2>
          <motion.p
            initial={{ scaleY: 0, opacity: 0 }}
            whileInView={{ scaleY: 1, opacity: 1 }}
            transition={{
              ease: "easeInOut",
              duration: 0.6,
              delay: 0.2,
            }}
          >
          {homeData?.sec_1_desc || "-"}
          </motion.p>
        </div>
        <div className="tri_grid">
          {dataList.map((item, j) => {
            return (
              <motion.div
                initial={{ translateY: 48, opacity: 0 }}
                whileInView={{ translateY: 0, opacity: 1 }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.6,
                  delay: 0.2 * j,
                }}
                className="tri_card"
                key={item.id}
              >
                <span className="icon"> 
                  <img src={`${baseUrl}/${item?.icon}`} alt="Icon" />
                </span>
                <h4>{item.title}</h4>
                <p>{item.text}</p>
                <ArrowBtn
                  text="Explore More"
                  clickHandler={() => navigate(`${item.link}`)}
                />
              </motion.div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default TriGridInfo;
