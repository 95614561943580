import "./Testimonials.scss";
import Slider from "react-slick";
import { baseUrl } from "../../../utils/apiData";

const Testimonials = ({ dataList }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    cssEase: "ease-in-out",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 950,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  return (
    <section className="testimonials p_b">
      <div className="content_wrap">
        <Slider {...settings}>
          {dataList?.map((data) => {
            return (
              <div className="feed_slide" key={data?._id}>
                <div className="card_slide">
                  <div className="info_wrap">
                    <div className="img_wrap">
                    <img src={`${baseUrl}/${data?.image}`} alt="profileImage" />
                    </div>
                    <h4>{data?.name}</h4>
                    <div dangerouslySetInnerHTML={{ __html: data?.content }} />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
