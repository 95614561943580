import { images } from "../../../utils/images";
import "./PortfolioDetailLanding.scss";

const PortfolioDetailLanding = ({img}) => {
    return(
        <div className="portfolio_detail">
                <img src={img} alt="interior" loading="lazy" />
        </div>
    )
}

export default PortfolioDetailLanding;