import { useNavigate } from "react-router-dom";
import "./TriNavigate.scss";
import { useState } from "react";
import { motion } from "framer-motion";

const TriNavigate = ({
  imgA,
  imgB,
  imgC,
  titleA,
  titleB,
  titleC,
  pathA,
  pathB,
  pathC,
}) => {
  const navigate = useNavigate();

  const [status, setStatus] = useState("");

  return (
    <section className={`tri_navigate ${status}`}>
      <div
        className="navigation_card"
        onClick={() => navigate(`${pathA}`)}
        onMouseEnter={() => setStatus("a")}
        onMouseLeave={() => setStatus("")}
      >
        <img src={imgA} alt="interior" loading="lazy" />
        <div className="overlay"></div>
        <motion.h2
             initial={{ scaleY: 0, opacity: 0 }}
             whileInView={{ scaleY: 1, opacity: 1 }}
             transition={{
               ease: "easeInOut",
               duration: 0.6,
               delay: 0.2,
             }}
             className="title_header"
        >{titleA}</motion.h2>
      </div>

      <div
        className="navigation_card"
        onClick={() => navigate(`${pathB}`)}
        onMouseEnter={() => setStatus("b")}
        onMouseLeave={() => setStatus("")}
      >
        <img src={imgB} alt="interior" loading="lazy" />
        <div className="overlay"></div>
        <motion.h2
             initial={{ scaleY: 0, opacity: 0 }}
             whileInView={{ scaleY: 1, opacity: 1 }}
             transition={{
               ease: "easeInOut",
               duration: 0.6,
               delay: 0.4,
             }}
             className="title_header"
        >{titleB}</motion.h2>
      </div>

      <div
        className="navigation_card"
        onClick={() => navigate(`${pathC}`)}
        onMouseEnter={() => setStatus("c")}
        onMouseLeave={() => setStatus("")}
      >
        <img src={imgC} alt="interior" loading="lazy" />
        <div className="overlay"></div>
        <motion.h2
             initial={{ scaleY: 0, opacity: 0 }}
             whileInView={{ scaleY: 1, opacity: 1 }}
             transition={{
               ease: "easeInOut",
               duration: 0.6,
               delay: 0.6,
             }}
             className="title_header"
        >{titleC}</motion.h2>
      </div>
    </section>
  );
};

export default TriNavigate;
